import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
	refreshSummary,
	getQuotes,
	resetQuote,
	saveQuote,
	generateEmail,
	setDraftQuote,
	createVersion,
	setStatus,
	updateQuote,
	setJobName,
	setCustomer,
	updateArchiveQuote,
	getArchivedQuotes,
	getDraftQuotes,
	selectAllEquipmentAndAccessories,
	generateQuotePdf,
	setShippingCost,
	setBillingContactAndAddressId,
	setShippingContactAndAddressId,
	generateEmailDraft,
	getAllQuoteDocuments,
	generateAllQandT,
	toggleCCFee,
} from "../../data/reducers/quote.reducer";
import { getCustomers } from "../../data/reducers/customerx.reducer";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import CustomerSelectionCard from "./CustomerSelectionCard";
import TermsCard from "./TermsCard";
import EquipmentList from "./EquipmentList";
import QuotePriceCard from "./QuotePriceCard";
import TicketsUtilityCard from "./TicketsUtilityCard";
import queryString from "query-string";
import DocumentCard from "./DocumentCard";
import "./single-quote.scss";
import {
	errorMessage,
	successMessage,
} from "../../data/reducers/alerts.reducer";
import { withLoader } from "../../utils/hoc/withLoader";
import swal from "sweetalert";
import { format } from "date-fns";

const AddQuote = ({ setBusy }) => {
	const dispatch = useDispatch();
	const inputRef = useRef();
	const history = useHistory();
	const location = useLocation();
	const [webLink, setWebLink] = useState("");
	const { customers } = useSelector((state) => state.customerXReducer);
	const selectedCustomer = useSelector(
		(state) => state.quoteReducer?.draftQuote?.quoteDetails?.selectedCustomer
	);

	const quoteId = queryString.parse(location.search).id;
	const customerId = queryString.parse(location.search).customerId;
	// console.log("customerId", customerId);
	const fetchAllCustomers = async () => {
		await dispatch(getCustomers());
	};
	// useEffect(() => {
	// 	inputRef.current.focus();
	// });
	useEffect(() => {
		fetchAllCustomers();
	}, []);
	useEffect(() => {
		if (customers && customerId && selectedCustomer === undefined) {
			let customer = customers.find(
				(customer) => customer?.customerDetails?.id === customerId
			);
			if (customer) {
				dispatch(setCustomer(customer));
			}
		}
	}, [customerId, customers, dispatch, selectedCustomer]);
	
	const { draftQuote, quotes, archivedQuotes, draftQuotes } = useSelector(
		(state) => state.quoteReducer
	);
	const { dropdowns } = useSelector((state) => state.configurationXReducer);
	const { products } = useSelector((state) => state.productsXReducer);
	const { accessories } = useSelector((state) => state.accessoryXReducer);
	const salesTax = useSelector((state) => state.salesTaxReducer)?.salesTaxes;
	// console.log(draftQuotes);
	const archived = () => {
		dispatch(updateArchiveQuote([draftQuote?.quoteDetails?.id]));
		dispatch(getArchivedQuotes());
		dispatch(getQuotes());
	};
	useEffect(() => {
		dispatch(refreshSummary());
	}, [draftQuote, dispatch]);

	useEffect(() => {
		fetchAllQuotes();
	}, []);

	const fetchAllQuotes = async () => {
		try {
			await dispatch(getQuotes());
			await dispatch(getArchivedQuotes());
			await dispatch(getDraftQuotes());
		} catch (error) {
			console.error(error);
		}
	};

	const setQuote = async (quote) => {
		let tempQuote = {
			...quote,
			lstQuoteEquipments: quote?.lstQuoteEquipments?.map((q) => ({
				...q,
				selected: true,
			})),
			lstQuoteAccessories: quote?.lstQuoteAccessories?.map((q) => ({
				...q,
				selected: true,
			})),
		};
		await dispatch(setDraftQuote(tempQuote));
		if (tempQuote.quoteDetails.creditCardFee > 0){
			await dispatch(toggleCCFee(true));
		}
	};
	useEffect(() => {
		if (quoteId === undefined) {
			if (!draftQuote) {
				dispatch(resetQuote());
				dispatch(setShippingCost(800));
			}
		} else {
			if (
				quotes !== undefined &&
				archivedQuotes !== undefined &&
				draftQuotes !== undefined &&
				quoteId !== undefined &&
				parseInt(quoteId) > 0
				// && (draftQuote === undefined || draftQuote.quoteDetails === undefined || draftQuote.quoteDetails.id === undefined)
			) {
				let quote = quotes.find(
					(quote) => quote?.quoteDetails?.id === parseInt(quoteId)
				);
				let archivedquote = archivedQuotes.find(
					(quote) => quote?.quoteDetails?.id === parseInt(quoteId)
				);
				let draftquotes = draftQuotes.find(
					(quote) => quote?.quoteDetails?.id === parseInt(quoteId)
				);
				if (quote) {
					setQuote(quote);
				} else if (archivedquote) {
					setQuote(archivedquote);
				} else {
					setQuote(draftquotes);
				}
			}
		}
	}, [quoteId, quotes, archivedQuotes, draftQuotes]);

	const createQuote = async () => {
		try {
			let quote = { ...draftQuote };
			quote.lstQuoteEquipments = quote?.lstQuoteEquipments?.map(
				({ id, ...equipment }) => equipment
			);
			quote.lstQuoteAccessories = quote?.lstQuoteAccessories?.map(
				({ id, ...accessory }) => accessory
			);
			var response = await dispatch(saveQuote(quote));
			if (!response.error) {
				await dispatch(successMessage("Quote created successfully!"));
				await dispatch(getDraftQuotes());
				history.push("/quote/edit?id=" + response.payload.quoteDetails.id);
			} else {
				dispatch(errorMessage("Error while creating quote."));
			}
		} catch (e) {
			console.log("e");
		}
	};

	const update = async (name) => {
		try {
			let quote = { ...draftQuote };
			setBusy(true);
			quote.lstQuoteEquipments = quote?.lstQuoteEquipments?.map(
				({ id, ...equipment }) => equipment
			);
			quote.lstQuoteAccessories = quote?.lstQuoteAccessories?.map(
				({ id, ...accessory }) => accessory
			);
			await dispatch(updateQuote(quote));
			await dispatch(successMessage("Quote updated successfully!"));
			await dispatch(getQuotes());
			await dispatch(getArchivedQuotes());
			await dispatch(getDraftQuotes());
		} catch (e) {
			console.log(e);
		} finally {
			if (name === "update") {
				pushToJobs();
			}
			setBusy(false);
		}
	};

	const pushToJobs = () => {
		if (parseInt(draftQuote?.quoteDetails?.status) === 9) {
			history.push("/archived");
		} else if (
			parseInt(draftQuote?.quoteDetails?.status) === 1 ||
			parseInt(draftQuote?.quoteDetails?.status) === 2 ||
			parseInt(draftQuote?.quoteDetails?.status) === 3
		) {
			history.push("/draft");
		} else {
			history.push("/");
		}
	};

	const generateMail = async (isDetailed) => {
		let data = {
			quoteId,
			date: new Date(draftQuote.quoteDetails.createdOn).toLocaleDateString(),
			jobName: draftQuote?.quoteDetails?.jobName,
			billingName: draftQuote?.quoteDetails?.customerName,
			billingCompanyName:
				(draftQuote.quoteDetails.billingContactAndAddress?.firstName != null &&
				draftQuote.quoteDetails.billingContactAndAddress?.firstName != undefined
					? draftQuote.quoteDetails.billingContactAndAddress?.firstName
					: "") +
				" " +
				(draftQuote.quoteDetails.billingContactAndAddress?.lastName != null &&
				draftQuote.quoteDetails.billingContactAndAddress?.lastName != undefined
					? draftQuote.quoteDetails.billingContactAndAddress?.lastName
					: ""),

			billingAddress1:
				draftQuote.quoteDetails.billingContactAndAddress?.addressLine1,
			billingAddress2:
				draftQuote.quoteDetails.billingContactAndAddress?.addressLine2,
			city1: draftQuote.quoteDetails.billingContactAndAddress?.city,
			state1: draftQuote.quoteDetails.billingContactAndAddress?.state,
			zipCode1: draftQuote.quoteDetails.billingContactAndAddress?.zipCode,
			billingPhone: draftQuote.quoteDetails.billingContactAndAddress?.phone,
			billingEmail: draftQuote.quoteDetails.billingContactAndAddress?.email,
			shippingName: draftQuote.quoteDetails.customerName,
			shippingCompanyName:
				(draftQuote.quoteDetails.shippingContactAndAddress?.firstName != null &&
				draftQuote.quoteDetails.shippingContactAndAddress?.firstName !=
					undefined
					? draftQuote.quoteDetails.shippingContactAndAddress?.firstName
					: "") +
				" " +
				(draftQuote.quoteDetails.shippingContactAndAddress?.lastName != null &&
				draftQuote.quoteDetails.shippingContactAndAddress?.lastName != undefined
					? draftQuote.quoteDetails.shippingContactAndAddress?.lastName
					: ""),

			shippingAddress1:
				draftQuote.quoteDetails.shippingContactAndAddress?.addressLine1,
			shippingAddress2:
				draftQuote.quoteDetails.shippingContactAndAddress?.addressLine2,
			city2: draftQuote.quoteDetails.shippingContactAndAddress?.city,
			state2: draftQuote.quoteDetails.shippingContactAndAddress?.state,
			zipCode2: draftQuote.quoteDetails.shippingContactAndAddress?.zipCode,
			notes: draftQuote.quoteDetails.specialNotes,
			shippingPhone: draftQuote.quoteDetails.shippingContactAndAddress?.phone,
			shippingEmail: draftQuote.quoteDetails.shippingContactAndAddress?.email,
			rentalTimeFrame: draftQuote.quoteDetails.rentalTimeFrame?.rentalTimeFrame,
			termsOfPayment: draftQuote.quoteDetails.termsOfPayment?.termsPayment,
			isDetailed: isDetailed,
			equipmentList: [
				...draftQuote.lstQuoteEquipments?.map((equipment) => ({
					equipmentCategory: products.find(
						(product) => product.id === equipment.equipmentId
					).equipmentCategoryName,
					equipmentId: equipment.equipmentId,
					model: products.find(
						(product) => product.id === equipment.equipmentId
					).modelOutputname,
					electrical: null,
					sizing: null,
					quantity: equipment.quantity + "",
					price: "$ " + formatMoney(equipment.price?.toFixed(2)),
					total: "$ " + formatMoney(equipment.total?.toFixed(2)),
				})),
				...draftQuote.lstQuoteAccessories?.map((accessory) => ({
					model: accessories.find((acc) => acc.id === accessory.accessoryId)
						? accessories.find((acc) => acc.id === accessory.accessoryId).name
						: "accessory",
					electrical: null,
					sizing: null,
					quantity: accessory.quantity + "",
					price: "$ " + formatMoney(accessory.price?.toFixed(2)),
					total: "$ " + formatMoney(accessory.total?.toFixed(2)),
				})),
			],
			billingDetailsValue: {
				shippingQuantity: draftQuote.quoteDetails?.shippingNumber,
				shippingPrice:
					"$ " + formatMoney(draftQuote.quoteDetails?.shippingCost?.toFixed(2)),
				shippingTotal:
					"$ " +
					formatMoney(draftQuote.quoteDetails?.shippingTotal?.toFixed(2)),
				otherQuantity: draftQuote.quoteDetails?.otherNumber,
				otherPrice:
					"$ " + formatMoney(draftQuote.quoteDetails?.otherCost?.toFixed(2)),
				otherTotal:
					"$ " + formatMoney(draftQuote.quoteDetails?.otherTotal?.toFixed(2)),
				subTotal:
					"$ " + formatMoney(draftQuote.quoteDetails?.subTotal.toFixed(2)),
				salesTaxPercentage: salesTax.find(
					(tax) => tax.id === draftQuote.quoteDetails.salesTaxId
				).salesTaxName,
				rate: draftQuote.quoteDetails.salesTaxVal.toString(),
				rateMultiplier: "1",
				rateMultiplicand: "",
				salesTaxTotal:
					"$ " + formatMoney(draftQuote.quoteDetails.salesTaxTotal.toFixed(2)),
				ccFeeMultiplier: draftQuote.quoteDetails.ccFeeEnabled && draftQuote.quoteDeatils.creditCardFee ? 1 : 0,
				ccFeeMultiplicand: null,
				ccFeeTotal:
					"$ " + formatMoney(draftQuote.quoteDetails.creditCardFee.toFixed(2)),
				billingTotalValue:
					"$ " + formatMoney(draftQuote.quoteDetails.totalQuote.toFixed(2)),
			},
		};
		try {
			setBusy(true);
			const { payload } = await dispatch(generateEmailDraft(data));
			if (payload?.data) {
				swal({
					title: "Mail draft has been created",
					text: "Are you sure that you want to leave this page and open draft",
					icon: "success",
					dangerMode: false,
					buttons: ["Close", "Open"],
				}).then((willOpen) => {
					if (willOpen) {
						window.open(payload?.data?.webLink, "_blank");
					} else {
						swal(
							"Not Opened!",
							"You can go to your mail box to see this draft.",
							"success"
						);
					}
				});
			} else {
				swal({
					title: "Couldn't create draft",
					text: "Please try again.",
					icon: "error",
					dangerMode: true,
				});
			}
			setWebLink(payload?.data?.webLink);
		} finally {
			setBusy(false);
			fetchQuoteDocuments();
		}
	};

	const onClickZipListener = async () => {
		let equipmentList = [];
		draftQuote?.lstQuoteEquipments?.forEach((equipment) => {
			const accessoriesForEquipment = draftQuote?.lstQuoteAccessories?.filter(
				(accessory) => accessory?.parentId === equipment?.identifier
			);
			equipmentList.push({
				equipmentCategory: products.find(
					(product) => product.id === equipment?.equipmentId
				).equipmentCategoryName,
				equipmentId: equipment?.equipmentId,
				model: products.find((product) => product.id === equipment?.equipmentId)
					?.modelOutputname,
				electrical: null,
				sizing: null,
				quantity: equipment?.quantity + "",
				price: "$ " + formatMoney(equipment?.price?.toFixed(2)),
				total: "$ " + formatMoney(equipment?.total?.toFixed(2)),
			});
			equipmentList?.push(
				...accessoriesForEquipment?.map((accessory) => ({
					model: accessories.find((acc) => acc.id === accessory.accessoryId)
						? accessories.find((acc) => acc.id === accessory.accessoryId)?.name
						: "accessory",
					electrical: null,
					sizing: null,
					quantity: accessory?.quantity + "",
					price: "$ " + formatMoney(accessory.price?.toFixed(2)),
					total: "$ " + formatMoney(accessory.total?.toFixed(2)),
				}))
			);
		});

		let data = {
			quoteId,
			jobNumber: quoteId,
			date: new Date().toLocaleString(),
			jobName: draftQuote?.quoteDetails?.jobName,
			billingName: draftQuote.quoteDetails.customerName,
			billingAddress1:
				draftQuote.quoteDetails.billingContactAndAddress?.addressLine1,
			billingAddress2:
				draftQuote.quoteDetails.billingContactAndAddress?.addressLine2,
			city1: draftQuote.quoteDetails.billingContactAndAddress?.city,
			state1: draftQuote?.quoteDetails?.billingContactAndAddress?.state,
			zipCode1: draftQuote.quoteDetails.billingContactAndAddress?.zipCode,
			billingPhone: draftQuote.quoteDetails.billingContactAndAddress?.phone,
			billingEmail: draftQuote.quoteDetails.billingContactAndAddress?.email,
			billingCompanyName:
				(draftQuote.quoteDetails.billingContactAndAddress?.firstName != null &&
				draftQuote.quoteDetails.billingContactAndAddress?.firstName != undefined
					? draftQuote.quoteDetails.billingContactAndAddress?.firstName
					: "") +
				" " +
				(draftQuote.quoteDetails.billingContactAndAddress?.lastName != null &&
				draftQuote.quoteDetails.billingContactAndAddress?.lastName != undefined
					? draftQuote.quoteDetails.billingContactAndAddress?.lastName
					: ""),
			shippingName: draftQuote.quoteDetails.customerName,
			shippingCompanyName:
				(draftQuote.quoteDetails.shippingContactAndAddress?.firstName != null &&
				draftQuote.quoteDetails.shippingContactAndAddress?.firstName !=
					undefined
					? draftQuote.quoteDetails.shippingContactAndAddress?.firstName
					: "") +
				" " +
				(draftQuote.quoteDetails.shippingContactAndAddress?.lastName != null &&
				draftQuote.quoteDetails.shippingContactAndAddress?.lastName != undefined
					? draftQuote.quoteDetails.shippingContactAndAddress?.lastName
					: ""),
			shippingAddress1:
				draftQuote?.quoteDetails?.shippingContactAndAddress?.addressLine1,
			shippingAddress2:
				draftQuote?.quoteDetails?.shippingContactAndAddress?.addressLine2,
			city2: draftQuote?.quoteDetails?.shippingContactAndAddress?.city,
			state2: draftQuote?.quoteDetails?.shippingContactAndAddress?.state,
			zipCode2: draftQuote?.quoteDetails?.shippingContactAndAddress?.zipCode,
			notes: draftQuote?.quoteDetails?.specialNotes,
			shippingPhone: draftQuote?.quoteDetails?.shippingContactAndAddress?.phone,
			shippingEmail: draftQuote?.quoteDetails?.shippingContactAndAddress?.email,
			rentalTimeFrame:
				draftQuote?.quoteDetails?.rentalTimeFrame?.rentalTimeFrame,
			termsOfPayment: draftQuote?.quoteDetails?.termsOfPayment?.termsPayment,
			equipmentList: equipmentList,
			billingDetailsValue: {
				shippingQuantity: draftQuote.quoteDetails?.shippingNumber,
				shippingPrice:
					"$ " + formatMoney(draftQuote.quoteDetails?.shippingCost?.toFixed(2)),
				shippingTotal:
					"$ " +
					formatMoney(draftQuote.quoteDetails?.shippingTotal?.toFixed(2)),
				otherQuantity: draftQuote.quoteDetails?.otherNumber,
				otherPrice:
					"$ " + formatMoney(draftQuote.quoteDetails?.otherCost?.toFixed(2)),
				otherTotal:
					"$ " + formatMoney(draftQuote.quoteDetails?.otherTotal?.toFixed(2)),
				subTotal:
					"$ " + formatMoney(draftQuote.quoteDetails?.subTotal.toFixed(2)),
				salesTaxPercentage: salesTax.find(
					(tax) => tax.id === draftQuote.quoteDetails.salesTaxId
				).salesTaxName,
				rate: draftQuote.quoteDetails.salesTaxVal.toString(),
				rateMultiplier: "1",
				rateMultiplicand: "",
				salesTaxTotal:
					"$ " + formatMoney(draftQuote.quoteDetails.salesTaxTotal.toFixed(2)),
				ccFeeMultiplier: draftQuote.quoteDetails.ccFeeEnabled && draftQuote.quoteDeatils.creditCardFee ? 1 : 0,
				ccFeeMultiplicand: null,
				ccFeeTotal:
					"$ " + formatMoney(draftQuote.quoteDetails.creditCardFee.toFixed(2)),
				billingTotalValue:
					"$ " + formatMoney(draftQuote.quoteDetails.totalQuote.toFixed(2)),
			},
		};
		const zipData = {
			jobName: draftQuote?.quoteDetails?.jobName,
			quoteId: draftQuote?.quoteDetails?.id,
			addToDocuments: true,
			dateEquipmentNeeded: format(new Date(), "MM/dd/yyyy"),
			billOfLanding:
				"" +
				draftQuote?.quoteDetails?.id +
				draftQuote?.quoteDetails?.customerId,
			billOfLanding1: "",
			customerName:
				(draftQuote.quoteDetails.shippingContactAndAddress?.firstName != null &&
				draftQuote.quoteDetails.shippingContactAndAddress?.firstName !==
					undefined
					? draftQuote.quoteDetails.shippingContactAndAddress?.firstName
					: "") +
				" " +
				(draftQuote.quoteDetails.shippingContactAndAddress?.lastName != null &&
				draftQuote.quoteDetails.shippingContactAndAddress?.lastName !==
					undefined
					? draftQuote.quoteDetails.shippingContactAndAddress?.lastName
					: ""),
			address1: draftQuote.quoteDetails.shippingContactAndAddress?.addressLine1,
			address2: draftQuote.quoteDetails.shippingContactAndAddress?.addressLine2,
			city: draftQuote.quoteDetails.shippingContactAndAddress?.city,
			state: draftQuote.quoteDetails.shippingContactAndAddress?.state,
			zipCode: draftQuote.quoteDetails.shippingContactAndAddress?.zipCode,
			phone: draftQuote.quoteDetails.shippingContactAndAddress?.phone,
			companyName: draftQuote.quoteDetails.customerName,
			rentalTimeFrame: draftQuote.quoteDetails.rentalTimeFrame?.rentalTimeFrame,
			notes: "",
			equipments: equipmentList,
			dateDelivered: format(new Date(), "MM/dd/yyyy"),
			signingDate: new Date(),
			invoiceNo: "",
			customerPONumber: "",
			serviceTicket: {
				serviceTicketDate: format(new Date(), "MM/dd/yyyy"),
				serviceTicketNo: "",
				timeDeparted: "",
				timeArrived: "",
				notes: "",
				signingDate: format(new Date(), "MM/dd/yyyy"),
			},
			shipping: {
				customerName:
					(draftQuote.quoteDetails.shippingContactAndAddress?.firstName !=
						null &&
					draftQuote.quoteDetails.shippingContactAndAddress?.firstName !=
						undefined
						? draftQuote.quoteDetails.shippingContactAndAddress?.firstName
						: "") +
					" " +
					(draftQuote.quoteDetails.shippingContactAndAddress?.lastName !=
						null &&
					draftQuote.quoteDetails.shippingContactAndAddress?.lastName !=
						undefined
						? draftQuote.quoteDetails.shippingContactAndAddress?.lastName
						: ""),
				companyName: draftQuote.quoteDetails.customerName,
				address1:
					draftQuote.quoteDetails.shippingContactAndAddress?.addressLine1,
				city: draftQuote.quoteDetails.shippingContactAndAddress?.city,
				zipCode: draftQuote.quoteDetails.shippingContactAndAddress?.zipCode,
				address2:
					draftQuote.quoteDetails.shippingContactAndAddress?.addressLine2,
				phone: draftQuote.quoteDetails.shippingContactAndAddress?.phone,
				email: draftQuote.quoteDetails.shippingContactAndAddress?.email,
			},
			billing: {
				customerName:
					(draftQuote.quoteDetails.billingContactAndAddress?.firstName !=
						null &&
					draftQuote.quoteDetails.billingContactAndAddress?.firstName !=
						undefined
						? draftQuote.quoteDetails.billingContactAndAddress?.firstName
						: "") +
					" " +
					(draftQuote.quoteDetails.billingContactAndAddress?.lastName != null &&
					draftQuote.quoteDetails.billingContactAndAddress?.lastName !=
						undefined
						? draftQuote.quoteDetails.billingContactAndAddress?.lastName
						: ""),
				companyName: draftQuote.quoteDetails.customerName,
				address1:
					draftQuote.quoteDetails.billingContactAndAddress?.addressLine1,
				address2:
					draftQuote.quoteDetails.billingContactAndAddress?.addressLine2,
				city: draftQuote.quoteDetails.billingContactAndAddress?.city,
				zipCode: draftQuote.quoteDetails.billingContactAndAddress?.zipCode,
				phone: draftQuote.quoteDetails.billingContactAndAddress?.phone,
				email: draftQuote.quoteDetails.billingContactAndAddress?.email,
			},
			innvoiveTable: [
				...draftQuote.lstQuoteEquipments?.map((equipment) => ({
					date: new Date().toLocaleDateString(),
					equipmentCategory: products?.find(
						(p) => p.id === equipment.equipmentId
					)?.equipmentCategoryName,
					model: products?.find((p) => p.id === equipment.equipmentId)
						.modelOutputname,
					electrical: null,
					sizing: null,
					quantity: equipment.quantity + "",
					price: "$ " + formatMoney(equipment.price?.toFixed(2)),
					total: "$ " + formatMoney(equipment.total?.toFixed(2)),
				})),
				...draftQuote.lstQuoteAccessories?.map((accessory) => ({
					date: new Date().toLocaleDateString(),
					model: accessories?.find((acc) => acc.id === accessory.accessoryId)
						? accessories?.find((acc) => acc.id === accessory.accessoryId).name
						: "accessory",
					electrical: null,
					sizing: null,
					quantity: accessory.quantity + "",
					price: "$ " + formatMoney(accessory.price?.toFixed(2)),
					total: "$ " + formatMoney(accessory.total?.toFixed(2)),
				})),
			],
			subTotal: "$ " + formatMoney(draftQuote.quoteDetails.subTotal.toFixed(2)),
			salesTax:
				"$ " + formatMoney(draftQuote.quoteDetails.salesTaxTotal.toFixed(2)),
			ccFee:
				"$ " + formatMoney(draftQuote.quoteDetails.creditCardFee.toFixed(2)),
			shippingFee:
				"$ " + formatMoney(draftQuote.quoteDetails.shippingTotal.toFixed(2)),
			total: "$ " + formatMoney(draftQuote.quoteDetails.totalQuote.toFixed(2)),
			...data,
		};
		try {
			setBusy(true);
			await dispatch(generateAllQandT(zipData));
		} catch (error) {
			console.log(error);
		} finally {
			setBusy(false);
			fetchQuoteDocuments();
		}
	};

	const createQuoteVersion = async () => {
		try {
			let response = await dispatch(
				createVersion({
					...draftQuote,
					quoteDetails: {
						...draftQuote.quoteDetails,
						id: 0,
						masterQuoteId: quoteId,
					},
				})
			);
			await fetchAllQuotes();
			if (response.error) {
				await dispatch(errorMessage(response.error.message));
			} else {
				history.push("/quote/edit?id=" + response.payload.quoteDetails.id);
				await dispatch(successMessage("copy created successfully!"));
			}
		} catch (ex) {
			await dispatch(errorMessage(ex));
		}
	};

	const quoteButtonClickListener = async (value) => {
		let equipmentList = [];
		draftQuote?.lstQuoteEquipments?.forEach((equipment) => {
			const accessoriesForEquipment = draftQuote?.lstQuoteAccessories?.filter(
				(accessory) => accessory?.parentId === equipment?.identifier
			);
			equipmentList.push({
				equipmentCategory: products.find(
					(product) => product.id === equipment?.equipmentId
				).equipmentCategoryName,
				equipmentId: equipment?.equipmentId,
				model: products.find((product) => product.id === equipment?.equipmentId)
					?.modelOutputname,
				electrical: null,
				sizing: null,
				quantity: equipment?.quantity + "",
				price: "$ " + formatMoney(equipment?.price?.toFixed(2)),
				total: "$ " + formatMoney(equipment?.total?.toFixed(2)),
			});
			equipmentList?.push(
				...accessoriesForEquipment?.map((accessory) => ({
					model: accessories.find((acc) => acc.id === accessory.accessoryId)
						? accessories.find((acc) => acc.id === accessory.accessoryId)?.name
						: "accessory",
					electrical: null,
					sizing: null,
					quantity: accessory?.quantity + "",
					price: "$ " + formatMoney(accessory.price?.toFixed(2)),
					total: "$ " + formatMoney(accessory.total?.toFixed(2)),
				}))
			);
		});

		let data = {
			quoteId,
			jobNumber: quoteId,
			date: new Date().toLocaleString(),
			jobName: draftQuote?.quoteDetails?.jobName,
			billingName: draftQuote.quoteDetails.customerName,
			billingAddress1:
				draftQuote.quoteDetails.billingContactAndAddress?.addressLine1,
			billingAddress2:
				draftQuote.quoteDetails.billingContactAndAddress?.addressLine2,
			city1: draftQuote.quoteDetails.billingContactAndAddress?.city,
			state1: draftQuote?.quoteDetails?.billingContactAndAddress?.state,
			zipCode1: draftQuote.quoteDetails.billingContactAndAddress?.zipCode,
			billingPhone: draftQuote.quoteDetails.billingContactAndAddress?.phone,
			billingEmail: draftQuote.quoteDetails.billingContactAndAddress?.email,
			billingCompanyName:
				(draftQuote.quoteDetails.billingContactAndAddress?.firstName != null &&
				draftQuote.quoteDetails.billingContactAndAddress?.firstName != undefined
					? draftQuote.quoteDetails.billingContactAndAddress?.firstName
					: "") +
				" " +
				(draftQuote.quoteDetails.billingContactAndAddress?.lastName != null &&
				draftQuote.quoteDetails.billingContactAndAddress?.lastName != undefined
					? draftQuote.quoteDetails.billingContactAndAddress?.lastName
					: ""),
			shippingName: draftQuote.quoteDetails.customerName,
			shippingCompanyName:
				(draftQuote.quoteDetails.shippingContactAndAddress?.firstName != null &&
				draftQuote.quoteDetails.shippingContactAndAddress?.firstName !=
					undefined
					? draftQuote.quoteDetails.shippingContactAndAddress?.firstName
					: "") +
				" " +
				(draftQuote.quoteDetails.shippingContactAndAddress?.lastName != null &&
				draftQuote.quoteDetails.shippingContactAndAddress?.lastName != undefined
					? draftQuote.quoteDetails.shippingContactAndAddress?.lastName
					: ""),
			shippingAddress1:
				draftQuote?.quoteDetails?.shippingContactAndAddress?.addressLine1,
			shippingAddress2:
				draftQuote?.quoteDetails?.shippingContactAndAddress?.addressLine2,
			city2: draftQuote?.quoteDetails?.shippingContactAndAddress?.city,
			state2: draftQuote?.quoteDetails?.shippingContactAndAddress?.state,
			zipCode2: draftQuote?.quoteDetails?.shippingContactAndAddress?.zipCode,
			notes: draftQuote?.quoteDetails?.specialNotes,
			shippingPhone: draftQuote?.quoteDetails?.shippingContactAndAddress?.phone,
			shippingEmail: draftQuote?.quoteDetails?.shippingContactAndAddress?.email,
			rentalTimeFrame:
				draftQuote?.quoteDetails?.rentalTimeFrame?.rentalTimeFrame,
			termsOfPayment: draftQuote?.quoteDetails?.termsOfPayment?.termsPayment,
			isDetailed: value,
			notes: draftQuote?.quoteDetails?.specialNotes,
			equipmentList: equipmentList,
			billingDetailsValue: {
				shippingQuantity: draftQuote.quoteDetails?.shippingNumber,
				shippingPrice:
					"$ " + formatMoney(draftQuote.quoteDetails?.shippingCost?.toFixed(2)),
				shippingTotal:
					"$ " +
					formatMoney(draftQuote.quoteDetails?.shippingTotal?.toFixed(2)),
				otherQuantity: draftQuote.quoteDetails?.otherNumber,
				otherPrice:
					"$ " + formatMoney(draftQuote.quoteDetails?.otherCost?.toFixed(2)),
				otherTotal:
					"$ " + formatMoney(draftQuote.quoteDetails?.otherTotal?.toFixed(2)),
				subTotal:
					"$ " + formatMoney(draftQuote.quoteDetails?.subTotal.toFixed(2)),
				salesTaxPercentage: salesTax.find(
					(tax) => tax.id === draftQuote.quoteDetails.salesTaxId
				).salesTaxName,
				rate: draftQuote.quoteDetails.salesTaxVal.toString(),
				rateMultiplier: "1",
				rateMultiplicand: "",
				salesTaxTotal:
					"$ " + formatMoney(draftQuote.quoteDetails.salesTaxTotal.toFixed(2)),
				ccFeeMultiplier: draftQuote.quoteDetails.ccFeeEnabled && draftQuote.quoteDeatils.creditCardFee > 0 ? 1 : 0,
				ccFeeMultiplicand: null,
				ccFeeTotal:
					"$ " + formatMoney(draftQuote.quoteDetails.creditCardFee.toFixed(2)),
				billingTotalValue:
					"$ " + formatMoney(draftQuote.quoteDetails.totalQuote.toFixed(2)),
			},
		};
		try {
			setBusy(true);
			await dispatch(generateQuotePdf(data));
		} finally {
			setBusy(false);
			fetchQuoteDocuments();
		}
	};

	const fetchQuoteDocuments = async () => {
		try {
			await dispatch(getAllQuoteDocuments());
		} catch (error) {
			console.error(error);
		} finally {
			setBusy(false);
		}
	};
	return (
		<>
			<ol className="breadcrumb mb-4">
				<li className="breadcrumb-item">
					<Link to="/">Home</Link>
				</li>
				<li className="breadcrumb-item">
					<Link to="/quotes">Quotes</Link>
				</li>
				<li className="breadcrumb-item active">
					{quoteId > 0 ? "Edit" : "Add New"} Quote
				</li>
			</ol>
			<div className="row">
				<div className="col-md-4">
					<h2>{quoteId > 0 ? "Edit" : "Add New"} Quote</h2>
				</div>
				<div className="col-md-8 text-right align-self-center">
					{draftQuote && quoteId ? (
						<button
							className="btn btn-success btn-sm m-1"
							onClick={(e) => {
								e.preventDefault();
								archived();
							}}
						>
							Archive
						</button>
					) : (
						""
					)}
					<button className="btn btn-primary btn-sm m-1">
						<ScrollLink to="UploadDocumentScroll" spy={true} smooth={true}>
							Upload Document
						</ScrollLink>
					</button>
					<button className="btn btn-primary btn-sm m-1">
						<ScrollLink to="TicketGenerationCard" spy={true} smooth={true}>
							Generate Tickets
						</ScrollLink>
					</button>
					<button
						className="btn btn-primary btn-sm m-1"
						onClick={onClickZipListener}
					>
						Download All Q&#38;T (zip)
					</button>
				</div>
			</div>
			<div className="row single-quote">
				<div className="col-md-12 col-lg-12">
					{draftQuote ? (
						<div className="card">
							<div className="card-body container-fluid">
								<form className="row form">
									<div className="col-md-4 form-group d-flex flex-column">
										<input
											// ref={inputRef}
											// ref={input => input && input.focus()}
											value={draftQuote?.quoteDetails?.jobName}
											onChange={(e) => {
												e.preventDefault();
												dispatch(setJobName(e.target.value));
											}}
											type="text"
											className="form-control form-control-sm"
											placeholder="Job Name"
										/>
									</div>
									{quoteId ? (
										<div className="col-md-2 form-group d-flex flex-column">
											<button
												className="btn btn-primary btn-sm form-control form-control-sm"
												onClick={(e) => {
													e.preventDefault();
													update();
												}}
											>
												Update
											</button>
										</div>
									) : (
										""
									)}
								</form>
							</div>
						</div>
					) : (
						""
					)}
					{draftQuote && quoteId ? (
						<div className="card mt-1">
							<div className="card-body container-fluid">
								<form className="row form">
									<div className="col-md-4 form-group d-flex flex-column">
										<div className="d-flex justify-content-between">
											<label>
												<b>Job No.</b>
											</label>
											<div className="text-right">
												<button
													className="btn btn-sm btn-link"
													onClick={(e) => {
														e.preventDefault();
														createQuoteVersion();
													}}
												>
													create a copy
												</button>
											</div>
										</div>
										<input
											value={quoteId}
											disabled
											className="form-control form-control-sm"
										></input>
									</div>
									<div className="col-md-4 form-group d-flex flex-column">
										<label>
											<b>Quote Status</b>
										</label>
										<select
											className="form-control form-control-sm"
											value={draftQuote?.quoteDetails?.status}
											onChange={(e) => {
												let status = dropdowns?.quoteStatus?.find(
													(st) => st.id === parseInt(e.target.value)
												);
												dispatch(setStatus(status.id));
											}}
										>
											<option value="0">---Select---</option>
											{dropdowns?.quoteStatus?.map((status) => (
												<option key={status.id} value={status.id}>
													{status.status}
												</option>
											))}
										</select>
									</div>
									<div className="col-md-2 form-group d-flex flex-column">
										<label>&nbsp;</label>
										<button
											className="btn btn-primary btn-sm form-control form-control-sm"
											onClick={(e) => {
												e.preventDefault();
												update();
											}}
										>
											Update
										</button>
									</div>
								</form>
							</div>
						</div>
					) : (
						""
					)}
					{draftQuote ? <CustomerSelectionCard /> : ""}
					{draftQuote ? <TermsCard /> : ""}
					{draftQuote ? <EquipmentList /> : ""}
					{/* {draftQuote ? <QuotePriceCard /> : ""} */}
					<div className="row mt-2">
						<div className="col-12 col-md-6">
							<button
								className="btn btn-primary btn-sm"
								onClick={(e) => {
									quoteId > 0 ? update("update") : createQuote();
								}}
							>
								{quoteId > 0 ? <span>Update &#38; Close</span> : "Save"}
							</button>
							&nbsp; &nbsp;
							{quoteId > 0 && (
								<button
									className="btn btn-primary btn-sm"
									onClick={(e) => {
										quoteId > 0 ? update() : createQuote();
									}}
								>
									Update
								</button>
							)}
							&nbsp; &nbsp;
							<button
								onClick={(e) => pushToJobs()}
								className="btn btn-danger btn-sm"
							>
								Go Back
							</button>
						</div>

						{draftQuote && quoteId ? (
							<>
								<div
									className="col-12 d-flex col-md-6"
									style={{ justifyContent: "flex-end" }}
								>
									<div className="mr-1 mt-1">
										<button
											className="btn btn-secondary btn-sm btn-block"
											onClick={() => quoteButtonClickListener(false)}
										>
											Quote(Summarized)
										</button>
									</div>
									<div className="mr-1 mt-1">
										<button
											className="btn btn-secondary btn-sm btn-block"
											onClick={() => quoteButtonClickListener(true)}
										>
											Quote(Detailed)
										</button>
									</div>
									<div className="mr-1 mt-1">
										<button
											className="btn btn-secondary btn-sm btn-block"
											onClick={(e) => {
												e.preventDefault();
												generateMail(false);
											}}
										>
											Email(Summarized)
										</button>
									</div>
									<div className="mr-1 mt-1">
										<button
											className="btn btn-secondary btn-sm btn-block"
											onClick={(e) => {
												e.preventDefault();
												generateMail(true);
											}}
										>
											Email(Detailed)
										</button>
									</div>
								</div>
							</>
						) : (
							<>
								<div
									className="col-12 d-flex col-md-6"
									style={{ justifyContent: "flex-end" }}
								>
									<div className="mr-1 mt-1">
										<button
											className="btn btn-secondary btn-sm btn-block"
											onClick={() => quoteButtonClickListener(false)}
										>
											Quote(Summarized)
										</button>
									</div>
									<div className="mr-1 mt-1">
										<button
											className="btn btn-secondary btn-sm btn-block"
											onClick={() => quoteButtonClickListener(true)}
										>
											Quote(Detailed)
										</button>
									</div>
									<div className="mr-1 mt-1">
										<button
											className="btn btn-secondary btn-sm btn-block"
											onClick={(e) => {
												e.preventDefault();
												generateMail(false);
											}}
										>
											Email(Summarized)
										</button>
									</div>
									<div className="mr-1 mt-1">
										<button
											className="btn btn-secondary btn-sm btn-block"
											onClick={(e) => {
												e.preventDefault();
												generateMail(true);
											}}
										>
											Email(Detailed)
										</button>
									</div>
								</div>
							</>
						)}
					</div>
					<br />
					{draftQuote && <TicketsUtilityCard setBusy={setBusy} />}
					{draftQuote && quoteId ? <DocumentCard quoteId={quoteId} /> : ""}
				</div>
			</div>
		</>
	);
};

export default withLoader(AddQuote);

const formatMoney = (number, decPlaces, decSep, thouSep) => {
	if (number === undefined) {
		number = 0;
	}
	var decPlaces = isNaN((decPlaces = Math.abs(decPlaces))) ? 2 : decPlaces,
		decSep = typeof decSep === "undefined" ? "." : decSep;
	thouSep = typeof thouSep === "undefined" ? "," : thouSep;
	var sign = number < 0 ? "-" : "";
	var i = String(
		parseInt((number = Math.abs(Number(number) || 0).toFixed(decPlaces)))
	);
	var j = (j = i.length) > 3 ? j % 3 : 0;

	return (
		sign +
		(j ? i.substr(0, j) + thouSep : "") +
		i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
		(decPlaces
			? decSep +
			  Math.abs(number - i)
					.toFixed(decPlaces)
					.slice(2)
			: "")
	);
};
